import { Component, Vue } from 'vue-property-decorator';
import { SwiperOptions } from '@/utils/swiperUtils';

import ArrowShort from '@/assets/icons/arrow-long.svg?inline';
import ArrowLong from '@/assets/icons/ArrowNextLong.svg?inline';
import Cross from '@/assets/icons/cross.svg?inline';
import PlayButton from '@/assets/icons/play-round.svg?inline';

import { PortraitCarouselBlockViewModel } from './PortraitCarouselModule.types';
import { getComponentProps } from '@/utils/vueUtils';

import ContentType from '@/common/interfaces/enums/ContentType';
import ContentCategory from '@/common/interfaces/enums/ContentCategory';

import ArticleCardComponent from '@/components/ArticleCard/ArticleCard.vue';
import { getDefaultSwiperOptions } from '@/utils/vueUtils';
import { BREAKPOINTS } from '@/common/interfaces/SizeBreakpoints';
import { getResizeImage } from '@/utils/commonUtils';
import CtaButton from '@/components/CtaButton/CtaButton.vue';

import CarouselPagination from '@/components/CarouselPagination/CarouselPagination.vue';
import CarouselArrow from '@/components/CarouselArrow/CarouselArrow.vue';

import { Getter, Mutation } from 'vuex-class';
import SizeBreakpoints from '@/common/interfaces/SizeBreakpoints';
import { mixins } from 'vue-class-component';
import SwiperMixin from '@/mixins/SwiperMixin';
import { CLEAR_MODULE_GROUP_URI } from '@/store/modules/modulesLoadModule';
import { SegmentElement } from '@/plugins/utm/SegmentElement';

@Component({
  components: {
    ArrowLong,
    ArrowShort,
    Cross,
    ArticleCardComponent,
    PlayButton,
    CarouselPagination,
    CarouselArrow,
    CtaButton,
  },
  props: getComponentProps<PortraitCarouselBlockViewModel>({
    Title: 'Wildlife and Nature',
    CtaLink: { Title: 'View More', Link: '#' },
    Items: [
      {
        StandFirst: 'Meet the inhabitants',
        ArticleLink: { Title: 'Underwater colour', Link: '#' },
        Image: {
          Url: getResizeImage('coral.jpg'),
          Alt: 'Coral',
        },
        Date: 'JUN 2019',
        Ship: 'NG Explorer',
        Type: ContentType.Read,
        Minutes: 4,
        Category: ContentCategory.WildLife,
        CategoryLink: '#',
      },
    ],
    IsRightToLeft: true,
  }),
})
export default class PortraitCarouselModule extends mixins(SwiperMixin) {
  p!: PortraitCarouselBlockViewModel;
  interactionCount: number = -1;
  realIndex: number = 0;

  activeIndex: number = 0;

  get swiperOption(): SwiperOptions {
    return {
      ...getDefaultSwiperOptions(this.uuid),
      slidesPerView: 'auto',
      spaceBetween: 40,
      centeredSlides: false,
      loop: this.p.Items.length > 3,
      speed: 700,
      observeParents: true,
      observer: true,
      breakpoints: {
        // breakpoints work down from these values not up
        [BREAKPOINTS.sm]: {
          spaceBetween: 10,
          slidesPerView: 1.1,
        },
        [BREAKPOINTS.lg]: {
          spaceBetween: 20,
        },
      },
      on: {
        transitionStart: () => this.onModuleInteraction(),
      },
    };
  }

  onModuleInteraction() {
    this.interactionCount++
    if (this.interactionCount === 1) {
      SegmentElement.sendEvent('moduleInteraction', {
        component_name: 'Article',
        module_type_id: this.$attrs['data-segmentid'],
        module_type_name: this.$attrs.type,
        interaction_type: 'carousel',
        position: this.realIndex + 1,
      })
    }
  }

  @Mutation
  [CLEAR_MODULE_GROUP_URI]: (groupUri: string) => void

  get IfShips() {
    return this.p.Items.filter((item) => item.Ship).length > 0;
  }

  get getBreakpoints() {
    return BREAKPOINTS;
  }

  @Getter isInBreakpoint!: (breakpoints: SizeBreakpoints[] | SizeBreakpoints) => boolean;
  @Getter windowSize!: SizeBreakpoints;

  windowWidth: number = 0;

  data() {
    return {
      tabletMobile: Boolean,
      mobile: Boolean,
    };
  }

  created() {
    if (!this.p.Items.length) {
      this[CLEAR_MODULE_GROUP_URI]('daily-expedition-reports')
    }
  }

  mounted() {
    this.swiper?.on('transitionStart', () => {
      this.activeIndex = this.swiper.activeIndex;
      this.realIndex = this.swiper.realIndex;
    });

    window.addEventListener('resize', this.handleResizeEvent);
    this.handleResizeEvent();
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResizeEvent);
  }

  handleResizeEvent() {
    let newWidth = document.documentElement.clientWidth;
    this.windowWidth = newWidth;
  }
}
